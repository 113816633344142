import React from "react";
import { Navigate } from "react-router-dom";




const AdminProtectedRoute = ({ element: Component, ...rest }) => {
  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const isAuthenticated = adminloginInfo?.token ? true : false;
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default AdminProtectedRoute;
