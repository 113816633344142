import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import AdminProtectedRoute from "./auth/AdminProtect.js";
import ManagerProtectedRoute from "./auth/ManagerProtect.js";
import UserProtectedRoute from "./auth/UserProtect.js";
import Backofficeprotect from "./auth/Backofficeprotect.js";
import { TimesheetApprovalRoute, ExpenseApprovalRoute } from "./auth/CustomProtect.js";
import Spinner from "../views/Spinner2/Spinner"
// import { SignIn, SignUp } from "@/pages/auth";

// const SignIn = lazy(() => import("../pages/auth/SignIn.jsx"));
// const SignUp = lazy(() => import("../pages/auth/SignUp.jsx"));
// const ForgotPass = lazy(() => import("../pages/auth/ForgotPass.jsx"));

const LoginPage = lazy(() => import("../pages/auth/Login/Login.js"));

const SignupPage = lazy(() => import("../pages/auth/signup/SignUp.js"));
const ForgotPass = lazy(() => import("../pages/auth/forgotPass/ForgotPass.js"));


const LoginPage2 = lazy(() => import("../pages/auth/Login copy/Login.js"));
const AdminDetailsTrack = lazy(() => import("../pages/adminDetailsTack/AdminDetailsTrack.js"));


/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));
const Timesheets = lazy(() => import("../views/timesheets/Timesheets.js"));
const Expenses = lazy(() => import("../views/expenses/Expenses.js"));
const ManageUsers = lazy(() => import("../views/users/ManageUsers"));
const AddUsers = lazy(() => import("../views/users/AddUsers.js"));
const Team = lazy(() => import("../views/users/Team.js"));
const Project = lazy(() => import("../views/project/Project.js"));
const Task = lazy(() => import("../views/task/Task.js"));
const Classes = lazy(() => import("../views/classes/Classes.js"));
const Assigns = lazy(() => import("../views/assigns/Assigns.js"));
const TimeOff = lazy(() => import("../views/settings/TimeOff.js"));
const Holiday = lazy(() => import("../views/settings/Holiday.js"));
const ChartOfAccounts = lazy(() => import("../views/settings/ChartOfAccounts.js"));
const CreditCards = lazy(() => import("../views/settings/CreditCards.js"));
const TimesheetSettings = lazy(() => import("../views/settings/TimesheetSettings.js"));
const ExpenseSettings = lazy(() => import("../views/settings/ExpenseSettings.js"));
const MyExpense = lazy(() => import("../views/myExpense/MyExpense.js"));
const TimesheetReport = lazy(() => import("../views/report/TimesheetReport.js"));
const ExpenseReport = lazy(() => import("../views/report/ExpenseReport.js"));
const TimeOffReport = lazy(() => import("../views/report/TimeOffReport.js"));
const DailyTime = lazy(() => import("../views/myTime/dailyView/DailyTime.js"));
const WeeklyView = lazy(() => import("../views/myTime/weeklyView/WeeklyView.js"));
const CalendarView = lazy(() => import("../views/myTime/calendarView/CalendarView.js"));
const WhoIsWroking = lazy(() => import("../views/whoIsWroking/WhoIsWroking.js"));
const SetUpAccount = lazy(() => import("../views/setUpAccount/SetUpAccount.js"));
const SetUpAccountNext = lazy(() => import("../views/setUpAccount/SetUpAccountNext.js"));
const Quickbooks = lazy(() => import("../views/integrations/Quickbooks.js"));
const Asana = lazy(() => import("../views/integrations/Asana.js"));
const Bamboohr = lazy(() => import("../views/integrations/Bamboohr.js"));
const Xero = lazy(() => import("../views/integrations/Xero.js"));
const Zoho = lazy(() => import("../views/integrations/Zoho.js"));
const Sage = lazy(() => import("../views/integrations/Sage.js"));
const Hubspot = lazy(() => import("../views/integrations/Hubspot.js"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

/*****Routes******/


const pageRoutes = [
  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", element: <LoginPage />, withoutFallback: true },
      { path: "/signup", element: <SignupPage />, withoutFallback: true },
      { path: "/forgot-password", element: <ForgotPass />, withoutFallback: true },
      { path: "/backoffice", element: <LoginPage2 />, withoutFallback: true },
      { path: "/backoffice/dashboard", element: <Backofficeprotect element={AdminDetailsTrack} />, withoutFallback: true },
    ],
  },
];

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/dashboard", exact: true, element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Dashboard1} /> </Suspense> },
      { path: "/my-expense", exact: true, element: <Suspense fallback={<Spinner />}><UserProtectedRoute element={MyExpense} /> </Suspense> },
      { path: "/timesheets", element: <Suspense fallback={<Spinner />}> <TimesheetApprovalRoute element={Timesheets} /> </Suspense> },
      { path: "/expenses", element: <Suspense fallback={<Spinner />}> <ExpenseApprovalRoute element={Expenses} /> </Suspense> },
      { path: "/users/manage-users", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={ManageUsers} /> </Suspense> },
      { path: "/users/add-users", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={AddUsers} /> </Suspense> },
      { path: "/users/manage-team", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Team} /> </Suspense> },
      { path: "/project/manage-project", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Project} /> </Suspense> },
      { path: "/project/manage-task", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Task} /> </Suspense> },
      { path: "/project/manage-classes", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Classes} /> </Suspense> },
      { path: "/project/manage-assigns", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Assigns} /> </Suspense> },
      { path: "/timesheet-settings/manage-time-off", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={TimeOff} /> </Suspense> },
      { path: "/timesheet-settings/manage-holiday", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Holiday} /> </Suspense> },
      { path: "/expense-settings/manage-chart-of-accounts", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={ChartOfAccounts} /> </Suspense> },
      { path: "/expense-settings/manage-credit-cards", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={CreditCards} /> </Suspense> },
      { path: "/timesheet-settings/timesheet", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={TimesheetSettings} /> </Suspense> },
      { path: "/expense-settings/expense", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={ExpenseSettings} /> </Suspense> },
      { path: "/setup-account", element: <Suspense fallback={<Spinner />}><AdminProtectedRoute element={SetUpAccount} /> </Suspense> },
      { path: "/setup-account/next", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={SetUpAccountNext} /> </Suspense> },
      { path: "/report/timesheet-report", element: <Suspense fallback={<Spinner />}> <ManagerProtectedRoute element={TimesheetReport} /> </Suspense> },
      { path: "/report/expense-report", element: <Suspense fallback={<Spinner />}> <ManagerProtectedRoute element={ExpenseReport} /> </Suspense> },
      { path: "/report/time-off-report", element: <Suspense fallback={<Spinner />}> <ManagerProtectedRoute element={TimeOffReport} /> </Suspense> },
      { path: "/time-entry/daily", element: <Suspense fallback={<Spinner />}> <UserProtectedRoute element={DailyTime} /> </Suspense> },
      { path: "/time-entry/weekly", element: <Suspense fallback={<Spinner />}> <UserProtectedRoute element={WeeklyView} /> </Suspense> },
      { path: "/time-entry/calendar", element: <Suspense fallback={<Spinner />}> <UserProtectedRoute element={CalendarView} /> </Suspense> },
      { path: "/who-is-working", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={WhoIsWroking} /> </Suspense> },
      { path: "/integration-QuickBooks", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Quickbooks} /> </Suspense> },
      { path: "/integration-Asana", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Asana} /> </Suspense> },
      { path: "/integration-BambooHR", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Bamboohr} /> </Suspense> },
      { path: "/integration-Xero", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Xero} /> </Suspense> },
      { path: "/integration-Zoho", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Zoho} /> </Suspense> },
      { path: "/integration-HubSpot", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Hubspot} /> </Suspense> },
      { path: "/integration-Sage", element: <Suspense fallback={<Spinner />}> <AdminProtectedRoute element={Sage} /> </Suspense> },
      { path: "/tables/basic-table", element: <Suspense fallback={<Spinner />}> <BasicTable /> </Suspense> },
      { path: "/form-layouts/form-layouts", element: <Suspense fallback={<Spinner />}> <FormLayouts /> </Suspense> },
      { path: "/form-elements/autocomplete", element: <Suspense fallback={<Spinner />}> <ExAutoComplete /> </Suspense> },
      { path: "/form-elements/button", element: <Suspense fallback={<Spinner />}> <ExButton /> </Suspense> },
      { path: "/form-elements/checkbox", element: <Suspense fallback={<Spinner />}><ExCheckbox /> </Suspense> },
      { path: "/form-elements/radio", element: <Suspense fallback={<Spinner />}><ExRadio /> </Suspense> },
      { path: "/form-elements/slider", element: <Suspense fallback={<Spinner />}> <ExSlider /> </Suspense> },
      { path: "/form-elements/switch", element: <Suspense fallback={<Spinner />}><ExSwitch /> </Suspense> },
    ],
  },
];



export { ThemeRoutes, pageRoutes };
