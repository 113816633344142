import React from "react";
import { Navigate } from "react-router-dom";

const TimesheetApprovalRoute = ({ element: Component, ...rest }) => {
  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
  
  const homeData = adminloginInfo || userLoginInfo;
  
  const isAuthenticated = typeof window !== 'undefined' && 
                         homeData && 
                         homeData.accessibility?.timesheets === true;

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};


const ExpenseApprovalRoute = ({ element: Component, ...rest }) => {
    const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
    const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
    
    const homeData = adminloginInfo || userLoginInfo;
    
    const isAuthenticated = typeof window !== 'undefined' && 
                           homeData && 
                           homeData.accessibility?.expenses === true;
  
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
  };

export { TimesheetApprovalRoute, ExpenseApprovalRoute };