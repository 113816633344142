import React, { createContext, useEffect, useState } from 'react';
import axios from "axios";
import api from '../Functions/api';
const MyContext = createContext();

const MyProvider = ({ children }) => {

  const loginInfo = JSON.parse(localStorage.getItem('adminLoginInfo')) || JSON.parse(localStorage.getItem('userLoginInfo'));

  const companyID = loginInfo?.companyID;
  const token = loginInfo?.token
  const inirialAccessibility = (loginInfo)?.accessibility

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const [loading, setLoading] = useState(false)
  const [showDemoAlert, setShowDemoAlert] = useState(false)
  const [reportFilteredData, setReportFilteredData] = useState();
  const [reportFilteredData2, setReportFilteredData2] = useState();
  const [expenseReportFilteredData, setExpenseReportFilteredData] = useState();
  const [sendPass, setSendPass] = useState(false)
  const [sendWelcome, setSendWelcome] = useState(true)
  const [activeItem, setActiveItem] = useState(null);
  const [activTab, setActivTab] = useState("user");
  const [newUser, setNewUser] = useState();
  const [selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet] = useState({
    Date: true,
    Task: true,
    Project: true,
    Hours: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
  });
  const [selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff] = useState({
    Date: true,
    Task: true,
    Balance: true,
    Hours: true,
    Status: true,
    Employee: true
  });
  const [selectedPropertiesForExpense, setSelectedPropertiesForExpense] = useState({
    Date: true,
    Expense: true,
    Project: true,
    Amount: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
  });
  const [loadingTimesheet, setLoadingTimesheet] = useState(false)
  const [show, setShow] = useState(false)

  const [additionalSettings, setAdditionalSettings] = useState({
    timer: false,
    employeeTime: false,
    clockIn: false,
    timesheetApprover: false,
    timesheetClasses: false,
    expenseApprover: false,
    receipt: false
  })

  const getSettings = async () => {
    try {
      const { data } = await api.get('/api/get-settings',
        {
          params: { companyID },
          headers: config.headers
        }
      );
      if (data) {
        setAdditionalSettings({
          timer: data.settings.timesheetSettings.timer,
          employeeTime: data.settings.timesheetSettings.employeeTime,
          clockIn: data.settings.timesheetSettings.clockIn,
          timesheetApprover: data.settings.timesheetSettings.approver,
          timesheetClasses: data.settings.timesheetSettings.classes,
          projectBillable: data.settings.timesheetSettings.projectBillable,
          expenseApprover: data.settings.expenseSettings.approver,
          receipt: data.settings.expenseSettings.receipt
        })
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const [accessibility, setAcceessibility] = useState(inirialAccessibility)
  const [checkNewUpdate, setCheckNewUpdate] = useState(false)

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    if (checkNewUpdate) {
      const loginInfo = JSON.parse(localStorage.getItem('adminLoginInfo')) || JSON.parse(localStorage.getItem('userLoginInfo'));

      const inirialAccessibility = (loginInfo)?.accessibility

      setAcceessibility(inirialAccessibility)
      setCheckNewUpdate(false)
    }
  }, [checkNewUpdate])

  const [haveLogo, setHaveLogo] = useState(true)
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  // from my Timesheet

  // Day View
  const [userTimesheetInfo, setUserTimesheetInfo] = useState([]);

  // from my expense

  const [userExpenseInfo, setUserExpenseInfo] = useState([]);

  // from dashboard

  const [projectInfo, setProjectInfo] = useState([])
  const [allUser, setAllUser] = useState([]);
  const [expenseInfo, setExpenseInfo] = useState([]);
  const [allUserTimesheetInfo, setAllUserTimesheetInfo] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [allSubAdmin, setAllSubAdmin] = useState([]);
  const [taskInfo, setTaskInfo] = useState([])
  const [allUserTimesheetInfoHold, setAllUserTimesheetInfoHold] = useState([]);
  const [allUserTimesheetInfoSubmited, setAllUserTimesheetInfoSubmited] = useState([]);

  // from classes

  const [classesInfo, setClassesInfo] = useState([]);

  // from time off

  const [policyInfo, setPolicyInfo] = useState([])

  // from holiday

  const [holidayInfo, setHolidayInfo] = useState([])

  // from chart of account 

  const [chartOfAccountInfo, setChartOfAccountInfo] = useState([]);

  // from credit card

  const [creditCardInfo, setCreditCardInfo] = useState([]);


  return (
    <MyContext.Provider
      value={{
        reportFilteredData2, setReportFilteredData2,
        isSidebarOpen, setSidebarOpen,
        haveLogo, setHaveLogo,
        accessibility, setAcceessibility,
        checkNewUpdate, setCheckNewUpdate,
        showDemoAlert, setShowDemoAlert,
        additionalSettings, setAdditionalSettings,
        newUser, setNewUser,
        activTab, setActivTab,
        selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff,
        show, setShow,
        loadingTimesheet, setLoadingTimesheet,
        setReportFilteredData, reportFilteredData,
        setExpenseReportFilteredData, expenseReportFilteredData,
        loading, setLoading,
        setSendPass, sendPass,
        sendWelcome, setSendWelcome,
        selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet,
        selectedPropertiesForExpense, setSelectedPropertiesForExpense,
        activeItem, setActiveItem,

        // from my Timesheet

        // Day View
        userTimesheetInfo, setUserTimesheetInfo,

        // from my expense 

        userExpenseInfo, setUserExpenseInfo,

        // from dashboard

        projectInfo, setProjectInfo,
        allUser, setAllUser,
        expenseInfo, setExpenseInfo,
        allUserTimesheetInfo, setAllUserTimesheetInfo,
        allManager, setAllManager,
        allSubAdmin, setAllSubAdmin,
        taskInfo, setTaskInfo,
        allUserTimesheetInfoHold, setAllUserTimesheetInfoHold,
        allUserTimesheetInfoSubmited, setAllUserTimesheetInfoSubmited,

        // from classes

        classesInfo, setClassesInfo,

        // from time off

        policyInfo, setPolicyInfo,

        // from holiday 

        holidayInfo, setHolidayInfo,

        // from chart of account 

        chartOfAccountInfo, setChartOfAccountInfo,

        // from credit card

        creditCardInfo, setCreditCardInfo
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyProvider, MyContext };
