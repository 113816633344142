import React from "react";
import { Navigate } from "react-router-dom";




const ManagerProtectedRoute = ({ element: Component, ...rest }) => {
  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
  const isAuthenticated = adminloginInfo?.token ? true : userLoginInfo?.token && userLoginInfo?.role === "manager" ? true : false;

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ManagerProtectedRoute;
